<template>
  <el-dialog class="setting" :visible.sync="visible" align-center :before-close="onClose">
    <div ref="chart" style="width: 100%;height: 300px;"></div>
  </el-dialog>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'EChartsDemo',
  data () {
    return {
      chart: null,
      option: null,
    };
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    }
  },
  methods: {
    onClose () {
      this.$emit("close");
    },
    getVirtulData (year) {
      return new Promise((resolve, reject) => {
        var date = +echarts.number.parseDate(year + '-01-02');
        var end = +echarts.number.parseDate((+year + 1) + '-01-02');
        var dayTime = 3600 * 24 * 1000;
        const chartData = [];

        this.$http({
          url: "/user/activeDays",
          method: 'get'
        })
          .then((response) => {
            const data = response;
            for (let time = date, k = 0; time < end && k < data.length; time += dayTime, k++) {
              chartData.push([
                echarts.time.format(new Date(time), "{yyyy}-{MM}-{dd}", true), // 正确的格式化方式
                parseInt(data[k], 2) // 修改为基数10
              ]);
            }
            resolve(chartData);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    initChart () {
      this.chart = echarts.init(this.$refs.chart);
      this.getVirtulData('2024').then(data => {
        console.log(data); // 第一个 console.log，确保数据已经获取
        const pieces = [
          { min: 0, max: 0, label: "未上线" },
          { min: 1, max: 31, label: "不活跃" },
          { min: 32, max: 63, label: "有点活跃" },
          { min: 64, max: 127, label: "很活跃" },
          { min: 128, max: 256, label: "非常活跃" },
        ];

        this.option = {
          title: {
            top: 30,
            left: 'center',
            text: '当前用户活跃度展示'
          },
          tooltip: {
            formatter: (params) => {
              const value = params.value[1]; // 获取当前值
              const piece = pieces.find(p => value >= p.min && value <= p.max);
              return `${params.value[0]}:  ${piece ? piece.label : 'unknown'}`;
            }
          },
          visualMap: {
            min: 0,
            max: 256,
            type: 'piecewise',
            orient: 'horizontal',
            left: 'right',
            top: 220,
            pieces: pieces,
            inRange: {
              color: ['#EAEDF0', '#9AE9A8', '#41C363', '#31A14E', '#206D38'],
              colorAlpha: 0.9,
            }
          },
          calendar: {
            top: 120,
            left: 30,
            right: 30,
            cellSize: 13,
            range: '2024',
            splitLine: { show: false },
            itemStyle: {
              borderWidth: 0.5
            },
            yearLabel: { show: false }
          },
          series: {
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: data
          }
        };
        this.chart.setOption(this.option);
      }).catch(error => {
        console.error('Failed to fetch virtual data:', error);
      });
    }
  },
  props: {
    visible: {
      type: Boolean
    }
  },
};
</script>

<style scoped>
</style>
