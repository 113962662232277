<template>
  <div class="login-view">
    <div class="login-content">
      <div class="login-intro">
        <div class="bigLogo">
          <svg t="1716188591270" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3093" width="256" height="256">
            <path d="M467.764374 81.382716A61.234568 61.234568 0 0 1 528.998942 142.617284v734.814815a61.234568 61.234568 0 0 1-122.469136 0v-734.814815A61.234568 61.234568 0 0 1 467.764374 81.382716zM284.06067 228.345679a48.987654 48.987654 0 0 1 48.987655 48.987654v465.382716a48.987654 48.987654 0 0 1-97.975309 0V277.333333a48.987654 48.987654 0 0 1 48.987654-48.987654zM651.468078 228.345679a48.987654 48.987654 0 0 1 48.987654 48.987654v465.382716a48.987654 48.987654 0 0 1-97.975309 0V277.333333a48.987654 48.987654 0 0 1 48.987655-48.987654z" fill="#40A3F1" p-id="3094"></path>
            <path d="M810.677954 301.82716a36.740741 36.740741 0 0 1 36.740741 36.740741v318.419753a36.740741 36.740741 0 0 1-73.481482 0v-318.419753a36.740741 36.740741 0 0 1 36.740741-36.740741zM124.850794 301.82716A36.740741 36.740741 0 0 1 161.591534 338.567901v318.419753a36.740741 36.740741 0 0 1-73.505975 0v-318.419753A36.740741 36.740741 0 0 1 124.850794 301.82716z" fill="#FFA70E" p-id="3095"></path>
          </svg>
        </div>
        <div>
          <h1>回声 IM</h1>
          <ul>
            <li>
              <a href="https://www.yuque.com/onejava/ci87m5/rtzzr44ook2m2f3c?singleDoc# 《底层模型》" target="_blank">开发文档</a>
            </li>
          </ul>
        </div>
        <div>
          <el-tag type="info">今日活跃用户</el-tag>
          <el-tag type="success">{{uv}}</el-tag>
        </div>
        <!--				<div>-->
        <!--					<h3>最近更新(2024-03-17)：</h3>-->
        <!--					<ul>-->
        <!--						<li>web端音视频功能优化:支持语音呼叫、会话中加入通话状态消息</li>-->
        <!--						<li>uniapp端支持音视频通话，并与web端打通</li>-->
        <!--						<li>uniapp端音视频源码通话源码暂未开源，需付费获取:-->
        <!--							<a href="https://www.yuque.com/u1475064/mufu2a/vi7engzluty594s2" target="_blank">uniapp端音视频通源码购买说明</a>-->
        <!--						</li>-->
        <!--					</ul>-->
        <!--				</div>-->
        <!--				<div>-->
        <!--					<h3>最近更新(2024-03-31)：</h3>-->
        <!--					<ul>-->
        <!--						<li>uniapp移动端支持发送语音消息</li>-->
        <!--					</ul>-->
        <!--				</div>-->
        <!--				<div>-->
        <!--					<h3>最近更新(2024-04-27)：</h3>-->
        <!--					<ul>-->
        <!--						<li>uniapp端加载离线消息慢以及卡顿问题优化</li>-->
        <!--						<li>web端样式风格调整</li>-->
        <!--					</ul>-->
        <!--				</div>-->
        <!--				<div>-->
        <!--					<h3>如果本项目对您有帮助,请在gitee上帮忙点个star</h3>-->
        <!--				</div>-->
        <!--				<div class="login-icons">-->
        <!--					<a class="login-icon">-->
        <!--						<img src="https://img.shields.io/badge/license-MIT-red" />-->
        <!--					</a>-->
        <!--					<a class="login-icon" href="https://gitee.com/bluexsx/box-im" target="_blank">-->
        <!--						<img src="https://gitee.com/bluexsx/box-im/badge/star.svg" />-->
        <!--					</a>-->
        <!--					<a class="login-icon" href="https://github.com/bluexsx/box-im" target="_blank">-->
        <!--						<img src="https://img.shields.io/github/stars/bluexsx/box-im.svg?style=flat&logo=GitHub" />-->
        <!--					</a>-->

        <!--				</div>-->
      </div>
      <el-form class="login-form" :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="60px" @keyup.enter.native="handleLogin('loginForm')">
        <div class="login-brand">登陆</div>
        <el-form-item label="终端" prop="userName" v-show="false">
          <el-input type="terminal" v-model="loginForm.terminal" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input type="userName" v-model="loginForm.userName" autocomplete="off" placeholder="用户名"></el-input>

        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" autocomplete="off" placeholder="密码"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleLogin('loginForm')">登陆</el-button>
          <el-button @click="resetForm('loginForm')">清空</el-button>
        </el-form-item>
        <div class="register">
          <router-link to="/register">没有账号,前往注册</router-link>
        </div>
        <div class="other-login">

          <el-divider>其他方式登录</el-divider>
          <div class="icon-container">
            <a target="_blank" :href="baseApiUrl+'/auth/render/github'">
              <svg t="1716105572291" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6042" width="36" height="36">
                <path d="M512 42.666667A464.64 464.64 0 0 0 42.666667 502.186667 460.373333 460.373333 0 0 0 363.52 938.666667c23.466667 4.266667 32-9.813333 32-22.186667v-78.08c-130.56 27.733333-158.293333-61.44-158.293333-61.44a122.026667 122.026667 0 0 0-52.053334-67.413333c-42.666667-28.16 3.413333-27.733333 3.413334-27.733334a98.56 98.56 0 0 1 71.68 47.36 101.12 101.12 0 0 0 136.533333 37.973334 99.413333 99.413333 0 0 1 29.866667-61.44c-104.106667-11.52-213.333333-50.773333-213.333334-226.986667a177.066667 177.066667 0 0 1 47.36-124.16 161.28 161.28 0 0 1 4.693334-121.173333s39.68-12.373333 128 46.933333a455.68 455.68 0 0 1 234.666666 0c89.6-59.306667 128-46.933333 128-46.933333a161.28 161.28 0 0 1 4.693334 121.173333A177.066667 177.066667 0 0 1 810.666667 477.866667c0 176.64-110.08 215.466667-213.333334 226.986666a106.666667 106.666667 0 0 1 32 85.333334v125.866666c0 14.933333 8.533333 26.88 32 22.186667A460.8 460.8 0 0 0 981.333333 502.186667 464.64 464.64 0 0 0 512 42.666667" fill="#231F20" p-id="6043"></path>
              </svg>
            </a>
            <a target="_blank" :href="baseApiUrl+'/auth/render/gitee'">
              <svg t="1716105523004" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4905" width="36" height="36">
                <path d="M512 960c-246.4 0-448-201.6-448-448s201.6-448 448-448 448 201.6 448 448-201.6 448-448 448z" fill="#D81E06" p-id="4906">
                </path>
                <path d="M721.664 467.968h-235.52a22.272 22.272 0 0 0-20.736 20.736v51.776c0 10.368 10.368 20.736 20.736 20.736H628.48c10.368 0 20.736 10.304 20.736 20.672v10.368c0 33.664-28.48 62.08-62.144 62.08H392.896a22.272 22.272 0 0 1-20.672-20.672V436.928c0-33.664 28.48-62.08 62.08-62.08h287.36a22.272 22.272 0 0 0 20.736-20.736v-51.84a22.272 22.272 0 0 0-20.736-20.672h-287.36A152.96 152.96 0 0 0 281.6 434.368v287.36c0 10.304 10.368 20.672 20.736 20.672h302.848c75.072 0 137.216-62.08 137.216-137.216v-116.48a22.272 22.272 0 0 0-20.736-20.736z" fill="#FFFFFF" p-id="4907">
                </path>
              </svg>
            </a>
          </div>

          <!--滑块验证-->
          <el-dialog title="请拖动滑块完成拼图" width="360px" :visible.sync="isShowSliderVerify" :close-on-click-modal="false" @closed="refresh" append-to-body>
            <slider-verify ref="sliderVerify" @success="onSuccess" @fail="onFail" @again="onAgain" />
          </el-dialog>

        </div>

      </el-form>
    </div>
    <icp></icp>
  </div>

</template>

<script>
import Icp from '../components/common/Icp.vue'
import sliderVerify from '../components/common/SliderVerify';
export default {
  name: "login",
  components: {
    sliderVerify,
    Icp
  },
  data () {
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'));
      }
      callback();
    };
    var checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      }
      callback();

    };


    return {
      isShowSliderVerify: false,
      loginForm: {
        terminal: this.$enums.TERMINAL_TYPE.WEB,
        userName: '',
        password: '',
        // 随机字符串
        nonceStr: '',
        // 验证值
        value: ''
      },
      rules: {
        userName: [{
          validator: checkUsername,
          trigger: 'blur'
        }],
        password: [{
          validator: checkPassword,
          trigger: 'blur'
        }]
      },
      uv: '',
      baseApiUrl: process.env.VUE_APP_BASE_API
    };
  },
  methods: {
    updateUV () {
      setTimeout(() => {
        // 这里ajax 请求的代码片段和判断是否停止定时器
        this.$http({
          url: "/resource/uv",
          method: 'get',
        })
          .then((data) => {
            this.uv = data;
          })
        // 如需要停止定时器，只需加入以下：
      }, 0)
    },
    handleLogin (formName) {
      let self = this;
      self.$refs[formName].validate((valid) => {
        self.isShowSliderVerify = valid;
      });

    },
    login () {
      this.$http({
        url: "/login",
        method: 'post',
        data: this.loginForm
      })
        .then((data) => {
          // 保存密码到cookie(不安全)
          this.setCookie('username', this.loginForm.userName);
          this.setCookie('password', this.loginForm.password);
          // 保存token
          sessionStorage.setItem("accessToken", data.accessToken);
          sessionStorage.setItem("refreshToken", data.refreshToken);
          this.$message.success("登陆成功");
          this.$router.push("/home/chat");
        }).catch(() => {
          let self = this;
          self.$refs.sliderVerify.verifyFailEvent();
        });

    },

    /* 滑动验证成功*/
    onSuccess (captcha) {
      Object.assign(this.loginForm, captcha);
      this.login();
    },
    /* 滑动验证失败*/
    onFail (msg) {
      // this.message('error', msg || '验证失败，请控制拼图对齐缺口');
    },
    /* 滑动验证异常*/
    onAgain () {
      this.message('error', '滑动操作异常，请重试');
    },
    /* 刷新验证码*/
    refresh () {
      this.$refs.sliderVerify.refresh();
    },
    /* 提示弹框*/
    message (type, message) {
      this.$message({
        showClose: true,
        type: type,
        message: message,
        duration: 1500,
      });
    },

    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    // 获取cookie、
    getCookie (name) {
      let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      let arr = document.cookie.match(reg)
      if (arr) {
        return unescape(arr[2]);
      }
      return '';
    },
    // 设置cookie,增加到vue实例方便全局调用
    setCookie (name, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate
        .toGMTString());
    },
    // 删除cookie
    delCookie (name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(name);
      if (cval != null) {
        document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
      }
    }
  },
  mounted () {
    this.loginForm.userName = this.getCookie("username");
    // cookie存密码并不安全，暂时是为了方便
    this.loginForm.password = this.getCookie("password");
    this.updateUV()
    let timer = setInterval(() => {
      this.updateUV()
    }, 3000)
  }

}
</script>

<style scoped lang="scss">
.login-view {
  width: 100%;
  height: 100vh; // 高度调整为视窗高度
  background: linear-gradient(to bottom right, #e8f2ff, #d4e1f5);
  background-size: cover;
  display: flex; // 使用flexbox布局
  justify-content: center; // 水平居中
  align-items: center; // 垂直居中
  box-sizing: border-box;

  .login-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px; // 缩小padding以适应布局
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.8); // 设置半透明背景
    border-radius: 10px; // 圆角边框
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    .login-intro {
      flex: 1;
      padding: 20px; // 缩小padding以适应布局
      max-width: 600px;
      text-align: center;

      .bigLogo {
        margin-bottom: 20px;

        .icon {
          width: 128px;
          height: 128px;
        }
      }

      h1 {
        font-size: 32px;
        color: #333;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin: 10px 0;

          a {
            color: #40a3f1;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .el-tag {
        margin-top: 20px;
      }
    }

    .login-form {
      height: 400px;
      width: 400px;
      padding: 40px 30px;
      background: white;
      opacity: 0.95;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;
      border: none;

      .login-brand {
        line-height: 50px;
        margin: 30px 0 40px 0;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        color: #40a3f1;
      }

      .el-form-item {
        margin-bottom: 20px;
      }

      .el-input {
        border-radius: 5px;
      }

      // .el-button {
      //   width: 100%;
      //   height: 40px;
      //   margin-top: 20px;
      //   border-radius: 5px;
      //   font-size: 16px;
      //   font-weight: 600;
      //   text-transform: uppercase;
      //   transition: all 0.3s ease;
      //   border: none;
      // }

      // .el-button--primary {
      //   background-color: #40a3f1;
      //   color: white;

      //   &:hover {
      //     background-color: #1e81ce;
      //   }
      // }

      // .el-button--default {
      //   background-color: #ffffff;
      //   color: #333;
      //   border: 1px solid #dcdcdc;

      //   &:hover {
      //     background-color: #f1f1f1;
      //     border-color: #c2c2c2;
      //   }
      // }

      // .el-button:first-child {
      //   margin-bottom: 10px;
      // }

      .register {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        a {
          color: #40a3f1;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .other-login {
        margin-top: 30px;

        .el-divider {
          margin: 20px 0;
        }

        .icon-container {
          display: flex;
          justify-content: center;
          gap: 20px;
        }
      }
    }
  }
}
</style>
